.timeline {
    height: 100%;
    position: relative;
    margin-top: 1rem;
}

.timeline-line {
    z-index: -10;
    border-left: 5px solid #a1a1aa;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    justify-self: center;
}

.timeline-grid {
    display: grid;
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: darkred;
    border: 3px solid #a1a1aa;
    margin: 0 auto;
    z-index: 10;
    position: absolute;
    justify-self: center;
}

.icon {
    z-index: 5000;
    color: white;
    font-size: 1.5rem;
    margin: 0 auto;
    display: table;
}

.clearfix {
    clear: both;
}

.column-left {
    float: left;
}

.column-right {
    float: right;
}

.column-center {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 1rem;
}

.fade-entrance {
    animation: entrance 2s ease 0s 1 normal forwards;
}

@keyframes entrance {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
