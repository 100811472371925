svg.heart {
    fill: rgb(242, 242, 242);
    height: 3.6rem;
    width: 3.6rem;
}

#radios label {
    position: relative;
}

input[type='radio'].imgButton {
    position: absolute;
    opacity: 0;
}

input[type='radio'].imgButton + svg.heart {
    --webkit-transition: all 0.2s;
    transition: all 0.2s;
}

input + svg.heart {
    cursor: pointer;
}

input[id='attending-yes'] + svg.heart:hover,
input[id='attending-yes']:checked + svg.heart,
input[id='attending-yes'] + svg.heart:focus {
    fill: rgb(239, 42, 16);
}

input[id='attending-no'] + svg.heart:hover,
input[id='attending-no']:checked + svg.heart,
input[id='attending-no'] + svg.heart:focus {
    fill: rgb(239, 42, 16);
}

input[id='attending-yes']:checked + svg.heart {
    animation: heart 0.6s linear 0.05s;
}
input[id='attending-no']:checked + svg.heart {
    animation: wobble-bottom 0.6s linear 0.05s;
}

.fade-entrance {
    animation: entrance 2s ease 0s 1 normal forwards;
}

@keyframes heart {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes wobble-bottom {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    15% {
        -webkit-transform: translateX(-10px) rotate(-6deg);
        transform: translateX(-10px) rotate(-6deg);
    }
    30% {
        -webkit-transform: translateX(5px) rotate(6deg);
        transform: translateX(5px) rotate(6deg);
    }
    45% {
        -webkit-transform: translateX(-5px) rotate(-3.6deg);
        transform: translateX(-5px) rotate(-3.6deg);
    }
    60% {
        -webkit-transform: translateX(3px) rotate(2.4deg);
        transform: translateX(3px) rotate(2.4deg);
    }
    75% {
        -webkit-transform: translateX(-2px) rotate(-1.2deg);
        transform: translateX(-2px) rotate(-1.2deg);
    }
}

@keyframes fade-enter {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
