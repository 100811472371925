.counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    margin: 2rem;
    text-decoration: none;
    color: #000;
}

.counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.counter .countdown > p {
    margin: 0;
}

.counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
}

@media (min-width: 640px) {
    .counter {
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 1.75rem;
        padding: 0.5rem;
        margin: 2rem;
    }

    .counter .countdown {
        line-height: 3rem;
        padding: 0 1.5rem 0 1.5rem;
    }

    .counter .countdown > span {
        font-size: 1rem;
        line-height: 1rem;
    }
}
