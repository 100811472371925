.banner-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10;
}

.data-display {
    background-color: rgba(255, 255, 255, 0.5);
}
