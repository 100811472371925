@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family:
        Great Vibes,
        Baskervville SC,
        Baskervville,
        Verdana;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
