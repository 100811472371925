.home_page_background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: absolute;
    inset: 0;
}

.text-overflow-center {
    margin-left: -100%;
    margin-right: -100%;
    text-align: center;
}
